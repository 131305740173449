import Vue from "vue";
import Vuex from "vuex";
import Utils from "@/utils/Utils";
// import lodash from 'lodash';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    websites: [],
    websiteAttributes: [],
    analyticsSelectedDevice: Utils.DEVICES[0],
    analyticsSelectedDate: Utils.getStartingDate(),
    configuration: {
      tier: {
        label: "Tier free",
        limitLabel: "5K",
        limit: 5000,
        limitMonth: 20000,
        limitMonthLabel: "20K",
      },
      balance: 0,
      remaining: 0,
    },
    billingInfo: null,
    currentConfiguration: { ...Utils.newEmptyConfig() },
    configurationModal: {
      type: "",
      list: [],
      title: "",
      desc: "",
      setupKey: "",
      selectedList: [],
      filter: "",
    },
    fineTuningModal: {
      type: "",
      list: [],
      title: "",
      desc: "",
      placeholder: "",
    },
    selectedPlan: {},
    userInfo: {},
    previewConfig: {},
    historyChanges: [],
    pendingRouting: "",
    openedWebsitesList: true,
    user: {},
    notifications: [],
    notificationModalVisible: false,
    notificationInterval: -1,
    newNotificationCounter: 0,
    deleteShopModalVisible: false,
    importCodeModalVisible: { visible: false, setupkey: "" },
    showSaveConfigModal: false,
    showDiscardChangesModal: false,
    selectedCampaign: null,
    selectedBanner: null,
    VMSelectedProducts: [],
    notificationsData: {},
    bannerPosition: -1,
    baseUrl: "",
    pendingWebsite: null,
    languages: [],
    chosenLanguage: false,
    selectedNotification: null,
    notificationDetailModalVisible: false,
  },
  getters: {
    getConfiguration: (state) => state.configuration,
    getShowSaveConfigModal: (state) => state.showSaveConfigModal,
    getShowDiscardChangesModal: (state) => state.showDiscardChangesModal,
    getSelectedNotification: (state) => state.selectedNotification,
    getBannerPosition: (state) => state.bannerPosition,
    getPendingWebsite: (state) => state.pendingWebsite,
    getSelectedPlan: (state) => state.selectedPlan,
    getAnalyticsSelectedDevice: (state) => state.analyticsSelectedDevice,
    getAnalyticsSelectedDate: (state) => state.analyticsSelectedDate,
    getChosenLanguage: (state) => state.chosenLanguage,
    getLanguages: (state) => state.languages,
    getBOLanguages: (state) =>
      state.languages.filter((item) =>
        Utils.LANGUAGE_OPTIONS.includes(item.value)
      ),
    getSelectedCampaign: (state) => state.selectedCampaign,
    getSelectedBanner: (state) => state.selectedBanner,
    getPreviewConfig: (state) => state.previewConfig,
    getWebsiteAttributes: (state) => state.websiteAttributes,
    getConfigurationModal: (state) => state.configurationModal,
    getVMSelectedProducts: (state) => state.VMSelectedProducts,
    getFineTuningModal: (state) => state.fineTuningModal,
    getDeleteShopModalVisible: (state) => state.deleteShopModalVisible,
    getImportCodeModalVisible: (state) => state.importCodeModalVisible,
    getNewNotificationCounter: (state) =>
      state.newNotificationCounter ? state.newNotificationCounter : 0,
    getNotificationModalVisible: (state) => state.notificationModalVisible,
    getNotificationDetailModalVisible: (state) =>
      state.notificationDetailModalVisible,
    getCurrentConfiguration: (state) => state.currentConfiguration,
    getHistoryChanges: (state) => state.historyChanges,
    getWebsites: (state) => state.websites,
    getNotificationsData: (state) => state.notificationsData,
    getPendingRouting: (state) => state.pendingRouting,
    getNotificationInterval: (state) => state.notificationInterval,
    getNotifications: (state) => state.notifications,
    getUserInfo: (state) => state.userInfo,
    getCompletedWebsites: (state) =>
      state.websites.filter((item) => Utils.checkCompleted(item)),
    getActiveWebsites: (state) =>
      state.websites.filter((item) => item.is_active),
    // getCompletedOrSyncedWebsites:state=>state.websites.filter(item=>item.is_active || Utils.checkSynced(item)),
    getIncompleteWebsites: (state) =>
      state.websites.filter((item) => !Utils.checkCompleted(item)),
    getConfiguringWebsites: (state) =>
      state.websites.filter((item) => Utils.checkConfiguring(item)),
    getToBeConfiguredWebsites: (state) =>
      state.websites.filter((item) => Utils.checkToBeConfigured(item)),
    getDisplayedWebsites: (state, getters) => [
      ...getters.getCompletedWebsites,
      ...getters.getConfiguringWebsites,
    ],
    getOpenedWebsitesList: (state) => state.openedWebsitesList,
    getBillingInfo: (state) => state.billingInfo,
    getTotalCounter: (state, getters) => {
      var totalCounter = 0;
      getters.getCompletedWebsites.forEach((website) => {
        totalCounter += parseInt(website.skus);
      });
      return totalCounter;
    },
    getTotalMonthly: (state, getters) => {
      var totalMonthly = 0;

      getters.getCompletedWebsites.forEach((website) => {
        totalMonthly += parseInt(website.requests);
      });
      return totalMonthly;
    },
    getBaseUrl: (state) => state.baseUrl,
  },
  mutations: {
    setConfiguration: (state, configuration) =>
      (state.configuration = configuration),
    setWebsites: (state, websites) => (state.websites = websites),
    setChosenLanguage: (state, chosenLanguage) =>
      (state.chosenLanguage = chosenLanguage),
    setNotificationsData: (state, notificationsData) =>
      (state.notificationsData = notificationsData),
    setUserInfo: (state, userInfo) => (state.userInfo = userInfo),
    setAnalyticsSelectedDevice: (state, analyticsSelectedDevice) =>
      (state.analyticsSelectedDevice = analyticsSelectedDevice),
    setAnalyticsSelectedDate: (state, analyticsSelectedDate) =>
      (state.analyticsSelectedDate = analyticsSelectedDate),
    setSelectedPlan: (state, selectedPlan) =>
      (state.selectedPlan = selectedPlan),
    setSelectedNotification: (state, selectedNotification) =>
      (state.selectedNotification = selectedNotification),
    setBannerPosition: (state, bannerPosition) =>
      (state.bannerPosition = bannerPosition),
    setLanguages: (state, languages) => (state.languages = languages),
    setPendingWebsite: (state, pendingWebsite) =>
      (state.pendingWebsite = pendingWebsite),
    setVMSelectedProducts: (state, VMSelectedProducts) =>
      (state.VMSelectedProducts = VMSelectedProducts),
    setShowDiscardChangesModal: (state, showDiscardChangesModal) =>
      (state.showDiscardChangesModal = showDiscardChangesModal),
    setSelectedCampaign: (state, selectedCampaign) =>
      (state.selectedCampaign = selectedCampaign),
    setSelectedBanner: (state, selectedBanner) =>
      (state.selectedBanner = selectedBanner),
    setShowSaveConfigModal: (state, showSaveConfigModal) =>
      (state.showSaveConfigModal = showSaveConfigModal),
    setWebsiteAttributes: (state, websiteAttributes) =>
      (state.websiteAttributes = websiteAttributes),
    setBaseUrl: (state, baseUrl) => (state.baseUrl = baseUrl),
    setPendingRouting: (state, pendingRouting) =>
      (state.pendingRouting = pendingRouting),
    setPreviewConfig: (state, previewConfig) =>
      (state.previewConfig = previewConfig),
    setNewNotificationCounter: (state, newNotificationCounter) =>
      (state.newNotificationCounter = newNotificationCounter),
    setNotifications: (state, notifications) =>
      (state.notifications = notifications),
    setHistoryChanges: (state, historyChanges) =>
      (state.historyChanges = historyChanges),
    setImportCodeModalVisible: (state, importCodeModalVisible) =>
      (state.importCodeModalVisible = importCodeModalVisible),
    setNotificationInterval: (state, notificationInterval) =>
      (state.notificationInterval = notificationInterval),
    setWebsiteConfiguration: (state, configuration) => {
      var websiteIndex = -1;
      state.websites.forEach((item, index) => {
        if (item.url == configuration.url) {
          websiteIndex = index;
          return false;
        }
      });
      if (websiteIndex > -1)
        Vue.set(state.websites, websiteIndex, configuration);
    },
    setNotificationModalVisible: (state, notificationModalVisible) =>
      (state.notificationModalVisible = notificationModalVisible),
    setNotificationDetailModalVisible: (
      state,
      notificationDetailModalVisible
    ) =>
      (state.notificationDetailModalVisible = notificationDetailModalVisible),
    setBillingInfo: (state, billingInfo) => (state.billingInfo = billingInfo),
    setConfigurationModal: (state, configurationModal) =>
      (state.configurationModal[configurationModal.field] =
        configurationModal.value),
    // setFineTuningModal:(state,fineTuningModal)=>state.fineTuningModal[fineTuningModal.field]=fineTuningModal.value,
    setFineTuningModal: (state, fineTuningModal) =>
      (state.fineTuningModal = fineTuningModal),
    setDeleteShopModalVisible: (state, deleteShopModalVisible) =>
      (state.deleteShopModalVisible = deleteShopModalVisible),
    setCurrentConfigurationByField: (state, currentConfiguration) =>
      (state.currentConfiguration[currentConfiguration.field] =
        currentConfiguration.value),
    setCurrentConfiguration: (state, currentConfiguration) =>
      (state.currentConfiguration = currentConfiguration),
    setOpenedWebsitesList: (state, openedWebsitesList) =>
      (state.openedWebsitesList = openedWebsitesList),
  },
  actions: {
    setConfiguration(context, configuration) {
      context.commit("setConfiguration", configuration);
    },
    setWebsites(context, websites) {
      context.commit("setWebsites", websites);
    },
    setChosenLanguage(context, chosenLanguage) {
      context.commit("setChosenLanguage", chosenLanguage);
    },
    setLanguages(context, languages) {
      context.commit("setLanguages", languages);
    },
    setSelectedPlan(context, selectedPlan) {
      context.commit("setSelectedPlan", selectedPlan);
    },
    setSelectedNotification(context, selectedNotification) {
      context.commit("setSelectedNotification", selectedNotification);
    },
    setBannerPosition(context, bannerPosition) {
      context.commit("setBannerPosition", bannerPosition);
    },
    setAnalyticsSelectedDevice(context, analyticsSelectedDevice) {
      context.commit("setAnalyticsSelectedDevice", analyticsSelectedDevice);
    },
    setAnalyticsSelectedDate(context, analyticsSelectedDate) {
      context.commit("setAnalyticsSelectedDate", analyticsSelectedDate);
    },
    setNotificationsData(context, notificationsData) {
      context.commit("setNotificationsData", notificationsData);
    },
    setVMSelectedProducts(context, VMSelectedProducts) {
      context.commit("setVMSelectedProducts", VMSelectedProducts);
    },
    setSelectedCampaign(context, selectedCampaign) {
      context.commit("setSelectedCampaign", selectedCampaign);
    },
    setSelectedBanner(context, selectedBanner) {
      context.commit("setSelectedBanner", selectedBanner);
    },
    setShowDiscardChangesModal(context, showDiscardChangesModal) {
      context.commit("setShowDiscardChangesModal", showDiscardChangesModal);
    },
    setHistoryChanges(context, historyChanges) {
      context.commit("setHistoryChanges", historyChanges);
    },
    setPendingWebsite(context, pendingWebsite) {
      context.commit("setPendingWebsite", pendingWebsite);
    },
    setImportCodeModalVisible(context, importCodeModalVisible) {
      context.commit("setImportCodeModalVisible", importCodeModalVisible);
    },
    setShowSaveConfigModal(context, showSaveConfigModal) {
      context.commit("setShowSaveConfigModal", showSaveConfigModal);
    },
    setPreviewConfig(context, previewConfig) {
      context.commit("setPreviewConfig", previewConfig);
    },
    setNotificationInterval(context, notificationInterval) {
      context.commit("setNotificationInterval", notificationInterval);
    },
    setNotifications(context, notifications) {
      context.commit("setNotifications", notifications);
    },
    setWebsiteAttributes(context, websiteAttributes) {
      context.commit("setWebsiteAttributes", websiteAttributes);
    },
    setBaseUrl(context, baseUrl) {
      context.commit("setBaseUrl", baseUrl);
    },
    setUserInfo(context, userInfo) {
      context.commit("setUserInfo", userInfo);
    },
    setNewNotificationCounter(context, newNotificationCounter) {
      context.commit("setNewNotificationCounter", newNotificationCounter);
    },
    setPendingRouting(context, pendingRouting) {
      context.commit("setPendingRouting", pendingRouting);
    },
    setWebsiteConfiguration(context, configuration) {
      context.commit("setWebsiteConfiguration", configuration);
    },

    setNotificationModalVisible(context, notificationModalVisible) {
      context.commit("setNotificationModalVisible", notificationModalVisible);
    },
    setNotificationDetailModalVisible(context, notificationDetailModalVisible) {
      context.commit(
        "setNotificationDetailModalVisible",
        notificationDetailModalVisible
      );
    },
    setDeleteShopModalVisible(context, deleteShopModalVisible) {
      context.commit("setDeleteShopModalVisible", deleteShopModalVisible);
    },
    setBillingInfo(context, billingInfo) {
      context.commit("setBillingInfo", billingInfo);
    },
    setConfigurationModal(context, configurationModal) {
      context.commit("setConfigurationModal", configurationModal);
    },
    setFineTuningModal(context, fineTuningModal) {
      context.commit("setFineTuningModal", fineTuningModal);
    },
    setCurrentConfigurationByField(context, currentConfiguration) {
      context.commit("setCurrentConfigurationByField", currentConfiguration);
    },
    setCurrentConfiguration(context, currentConfiguration) {
      context.commit("setCurrentConfiguration", currentConfiguration);
    },
    setOpenedWebsitesList(context, openedWebsitesList) {
      context.commit("setOpenedWebsitesList", openedWebsitesList);
    },
  },
  modules: {},
});
