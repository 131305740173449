<template>
  <div class="visual-machine-banners">
    <b-modal
      id="discard-config-modal"
      :visible="showDiscardChangesModal"
      hide-footer
      hide-header
      size="lg"
      @hide="showDiscardChangesModal = false"
    >
      <div class="close" @click="showDiscardChangesModal = false"></div>
      <div class="title">{{ $t("visualMachine.discard") }}</div>
      <div class="desc">{{ $t("visualMachine.unsaved") }}</div>
      <div class="footer">
        <div class="button save" @click="exitWithoutSave">
          {{ $t("visualMachine.saving") }}
        </div>
        <div class="button discard" @click="showDiscardChangesModal = false">
          {{ $t("visualMachine.cancel") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="delete-trigger-config-modal"
      :visible="showDeleteTriggerModal"
      hide-footer
      hide-header
      size="lg"
      @hide="showDeleteTriggerModal = false"
    >
      <div class="close" @click="showDeleteTriggerModal = false"></div>
      <div class="title">
        {{
          $t("visualMachine.deleteTrigger", { var1: deletingTriggerIndex + 1 })
        }}
      </div>
      <div class="desc">{{ $t("visualMachine.recoverTrigger") }}</div>
      <div class="footer">
        <div
          class="button save"
          @click="
            selectedBanner.conditions.conditions.splice(deletingTriggerIndex, 1)
            showDeleteTriggerModal = false
          "
        >
          {{ $t("visualMachine.delete") }}
        </div>
        <div class="button discard" @click="showDeleteTriggerModal = false">
          {{ $t("visualMachine.cancel") }}
        </div>
      </div>
    </b-modal>
    <b-modal
      id="delete-campaign-modal"
      :visible="showDeleteModal"
      hide-footer
      hide-header
      size="lg"
      @hide="showDeleteModal = false"
    >
      <div class="close" @click="showDeleteModal = false"></div>
      <div class="title">{{ $t("visualMachine.deleteCampaign?") }}</div>
      <div class="desc">{{ $t("visualMachine.recoverCampaign") }}</div>
      <div class="footer">
        <div
          class="button save"
          @click="
            deleteCampaign(selectedBanner.id)
            showDeleteModal = false
            selectedBanner = null
          "
        >
          {{ $t("visualMachine.delete") }}
        </div>
        <div class="button discard" @click="showDeleteModal = false">
          {{ $t("visualMachine.cancel") }}
        </div>
      </div>
    </b-modal>
    <ASPanel
      :showPanel="showPanel"
      :actionType="actionType"
      @closeASPanel="onClosePanel"
      ref="aspanel"
      :actions="actions"
    />
    <perfect-scrollbar>
      <div
        :class="['content-container', { 'with-dynamic': toggleDynamic }]"
        v-if="!selectedBanner"
      >
        <div class="top">
          <div class="search">
            <b-input
              v-model="filterWord"
              :placeholder="
                $t('visualMachine.searchAmong', { var1: bannersList.length })
              "
            >
            </b-input>
            <div
              class="new-campaign"
              @click="selectCampaign($options.EMPTY_BANNER)"
            >
              + {{ $t("visualMachine.newCampaign") }}
            </div>
            <div class="dynamic-toggle">
              <div class="value">
                <span>
                  <span class="icon" @mouseenter="$bvToast.show('id-toast')">
                  </span>
                  {{ $t("banners.useDynamicBlocks") }}
                </span>
                <b-toast
                  id="id-toast"
                  variant="info"
                  static
                  no-close-button
                  solid
                  auto-hide-delay="2000"
                >
                  {{ $t("banners.enableDynamicBlock") }} <br />
                  {{ $t("banners.desktopId") }} <br />
                  {{ $t("banners.mobileId") }}
                </b-toast>
                <toggle-button
                  class="toggle-button"
                  color="#398ff4"
                  :sync="true"
                  :width="50"
                  v-model="toggleDynamic"
                  @change="dynamicToggleAction"
                />
              </div>
            </div>
          </div>
          <div class="toggle" v-if="filterWord == ''">
            {{ $t("visualMachine.enableDisableAll") }}
            <toggle-button
              class="toggle-button"
              color="#398ff4"
              :sync="true"
              :width="50"
              v-model="toggledAll"
              @change="toggleAll"
            />
          </div>
          <div class="search-results" v-else>
            {{ $t("visualMachine.searchResultsFor") }} "<span>{{
              filterWord
            }}</span
            >"
          </div>
        </div>
        <div class="toggle"></div>
        <div :class="['list-container', { searching: filterWord !== '' }]">
          <div class="header">
            <div class="drag column-item">
              <div
                class="info"
                @mouseenter="$bvToast.show('drag-toast')"
                @mouseleave="$bvToast.hide('drag-toast')"
              ></div>
              <b-toast
                id="drag-toast"
                variant="info"
                static
                no-close-button
                no-auto-hide
                solid
              >
                {{ $t("visualMachine.canOrder") }}
              </b-toast>
            </div>
            <div class="name column-item">{{ $t("visualMachine.name") }}</div>
            <div class="enable column-item">
              {{ $t("visualMachine.enableDisable") }}
            </div>
            <div class="validity column-item">
              {{ $t("visualMachine.visibility") }}
            </div>
            <div class="option column-item">
              {{ $t("visualMachine.option") }}
            </div>
          </div>
          <draggable
            v-model="bannersList"
            v-if="!isloadingBannersList && filterWord == ''"
            class="campaign-list"
            @end="saveAll"
            v-bind="$options.DRAG_OPTIONS"
          >
            <transition-group type="transition">
              <div
                :class="['list-item']"
                v-for="campaign in bannersListFiltered"
                :key="'campaign_' + campaign.id"
              >
                <div class="drag column-item"><div class="icon"></div></div>
                <div class="name column-item">
                  {{ campaign.name }}
                </div>
                <div class="enable column-item">
                  <toggle-button
                    class="toggle-button"
                    color="#398ff4"
                    :sync="true"
                    :width="50"
                    v-model="campaign.isActive"
                    @change="() => onChange(campaign)"
                  />
                </div>
                <div class="validity column-item">
                  {{
                    campaign.validity.type == validityOptions[0].value
                      ? $t("visualMachine.alwaysVisible")
                      : validityText(campaign.validity)
                  }}
                </div>
                <div class="option column-item">
                  <div
                    class="btn copy"
                    @click="copyCampaign(campaign)"
                    @click.stop
                  ></div>
                  <div
                    class="btn"
                    @click="selectCampaign(campaign)"
                    @click.stop
                  >
                    {{ $t("visualMachine.editRule") }}
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
          <div
            class="spinner-container"
            v-else-if="isloadingBannersList && filterWord == ''"
          >
            <b-spinner class="align-middle"></b-spinner>
          </div>
          <div class="campaign-list" v-else-if="!isloadingBannersList">
            <div
              :class="['list-item']"
              v-for="campaign in bannersListFiltered"
              :key="'campaign_' + campaign.id"
            >
              <div class="name column-item">{{ campaign.name }}</div>
              <div class="enable column-item">
                <toggle-button
                  class="toggle-button"
                  color="#398ff4"
                  :sync="true"
                  :width="50"
                  v-model="campaign.isActive"
                  @change="() => onChange(campaign)"
                />
              </div>
              <div class="validity column-item">
                {{
                  campaign.validity.type == validityOptions[0].value
                    ? $t("visualMachine.alwaysVisible")
                    : validityText(campaign.validity)
                }}
              </div>
              <div class="option column-item">
                <div
                  class="btn copy"
                  @click="copyCampaign(campaign)"
                  @click.stop
                ></div>
                <div class="btn" @click="selectCampaign(campaign)" @click.stop>
                  {{ $t("visualMachine.editRule") }}
                </div>
              </div>
            </div>
          </div>
          <div class="spinner-container" v-else>
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </div>
      </div>
      <div class="content-container" v-else>
        <div class="top-container">
          <div class="btn back" @click="showDiscardChangesModal = true">
            {{ $t("visualMachine.goBack") }}
          </div>
          <div class="title">{{ selectedBanner.name }}</div>
        </div>
        <div
          :class="[
            'collapse-container',
            { expanded: campaignInfoExpanded },
            { 'with-error': cantAddName && touchedName },
          ]"
        >
          <div
            class="header-collapse"
            @click="campaignInfoExpanded = !campaignInfoExpanded"
          >
            <div
              class="warning info"
              v-if="cantAddName && touchedName"
              @mouseenter="$bvToast.show('info-toast')"
              @mouseleave="$bvToast.hide('info-toast')"
            >
              <div class="warn-icon"></div>
              <b-toast
                id="info-toast"
                variant="danger"
                static
                no-close-button
                no-auto-hide
                solid
              >
                {{ $t("banners.addBannerName") }}
              </b-toast>
            </div>
            <div class="title-container">
              <div class="label-container">
                <div class="sub-title">{{ $t("banners.bannerInfo") }}</div>
                <div class="desc">{{ $t("banners.editBanner") }}</div>
              </div>
            </div>
            <div class="icon"></div>
          </div>
          <b-collapse accordion="accordion" v-model="campaignInfoExpanded">
            <div class="accordion-content">
              <div class="row">
                <div class="column">
                  <div class="label">{{ $t("banners.bannerName") }}</div>
                  <b-input
                    v-model="selectedBanner.name"
                    @input="touchedName = true"
                  ></b-input>
                </div>
                <div class="column">
                  <div class="label">{{ $t("banners.bannerDesc") }}</div>
                  <b-textarea v-model="selectedBanner.description"></b-textarea>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div
          :class="[
            'collapse-container',
            { expanded: schedulingExpanded },
            { 'with-error': cantAddValidity },
          ]"
        >
          <div
            class="header-collapse"
            @click="schedulingExpanded = !schedulingExpanded"
          >
            <div
              class="warning validity"
              v-if="cantAddValidity"
              @mouseenter="$bvToast.show('validity-toast')"
              @mouseleave="$bvToast.hide('validity-toast')"
            >
              <div class="warn-icon"></div>
              <b-toast
                id="validity-toast"
                variant="danger"
                static
                no-close-button
                no-auto-hide
                solid
              >
                {{ $t("visualMachine.addValidity") }}
              </b-toast>
            </div>
            <div class="title-container">
              <div class="label-container">
                <div class="sub-title">
                  {{ $t("visualMachine.scheduling") }}
                </div>
                <div class="desc">{{ $t("banners.timeRange") }}</div>
              </div>
            </div>
            <div class="icon"></div>
          </div>
          <b-collapse accordion="accordion" v-model="schedulingExpanded">
            <div class="accordion-content">
              <div class="row">
                <div class="column">
                  <b-form-group>
                    <b-form-radio
                      v-model="selectedBanner.validity.type"
                      v-for="option in validityOptions"
                      @change="timeframes = {}"
                      :key="'timeframe_' + option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-group>
                </div>
                <div
                  class="column"
                  v-if="
                    selectedBanner.validity.type == validityOptions[1].value
                  "
                >
                  <div class="label">{{ $t("visualMachine.timeFrame") }}</div>
                  <Datepicker
                    v-model="timeframes"
                    :opens="'left'"
                    :ranges="false"
                    :timePicker="true"
                    :locale-data="locale"
                    :timePicker24Hour="true"
                  />
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div v-for="(trigger, index) in triggers" :key="'trigger_' + index">
          <div class="trigger-divider" v-if="index > 0">
            <div class="bar"></div>
            <div class="or">{{ $t("visualMachine.or") }}</div>
            <div class="bar"></div>
          </div>
          <div
            :class="[
              'collapse-container',
              { expanded: triggersExpanded['trigger_' + index] },
              { 'with-error': cantAddFilterArray[index] && touchedTrigger },
            ]"
          >
            <div
              class="header-collapse"
              @click="() => expandAccordion('trigger', index)"
            >
              <div
                class="warning filter"
                v-if="cantAddFilterArray[index] && touchedTrigger"
                @mouseenter="$bvToast.show('filter-toast_' + index)"
                @mouseleave="$bvToast.hide('filter-toast_' + index)"
              >
                <div class="warn-icon"></div>
                <b-toast
                  :id="'filter-toast_' + index"
                  variant="danger"
                  static
                  no-close-button
                  no-auto-hide
                  solid
                >
                  <span
                    v-for="(warning, warningIndex) in cantAddFilterWarnings(
                      index
                    )"
                    :key="'warning_' + index + '_' + warningIndex"
                  >
                    <br v-if="warningIndex > 0" />
                    {{ warning }}
                  </span>
                </b-toast>
              </div>
              <div class="title-container">
                <div
                  class="delete"
                  v-if="index > 0"
                  @click="deleteTrigger(index)"
                ></div>
                <div class="label-container">
                  <div class="sub-title">
                    {{ $t("visualMachine.trigger", { var1: index + 1 }) }}
                  </div>
                  <div class="desc">{{ $t("banners.triggerDesc") }}</div>
                </div>
              </div>
              <div class="icon"></div>
            </div>
            <b-collapse
              accordion="accordion"
              v-model="triggersExpanded['trigger_' + index]"
            >
              <div class="accordion-content">
                <div class="container">
                  <div class="sub-title">{{ $t("visualMachine.queries") }}</div>
                  <div class="label">{{ $t("banners.queriesLabel") }}</div>
                  <div class="divider"></div>
                  <div
                    class="query-input-container"
                    v-for="(query, queryIndex) in trigger.queries"
                    :key="'query_' + index + queryIndex"
                  >
                    <div
                      class="delete"
                      @click="deleteQuery(index, queryIndex)"
                    ></div>
                    <div class="type">
                      <b-select v-model="query.type" :options="queryOptions">
                      </b-select>
                    </div>
                    <div
                      class="value"
                      v-if="
                        query.type != null &&
                          query.type != queryOptions.slice(-1)[0].value
                      "
                    >
                      <b-input v-model="query.value"> </b-input>
                    </div>
                  </div>
                  <div class="btn-container">
                    <div
                      :class="[
                        'add',
                        'btn',
                        { disabled: cantAdd[index].query },
                      ]"
                      @click="
                        addQuery(index)
                        touchedTrigger = true
                      "
                    >
                      {{ $t("visualMachine.addQuery") }}
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="sub-title">{{ $t("visualMachine.facets") }}</div>
                  <div class="label">{{ $t("banners.facetsLabel") }}</div>
                  <div class="divider"></div>
                  <div class="match-exact-container">
                    <div
                      class="sub-desc"
                      v-if="trigger.facets.filters.length > 0"
                    >
                      {{ $t("visualMachine.choose") }}
                    </div>
                    <div class="sub-desc" v-else>
                      {{ $t("visualMachine.justQueryChoose") }}
                    </div>
                    <b-select
                      v-model="trigger.facets.type"
                      :options="
                        trigger.facets.filters.length > 0
                          ? matchExactOptions
                          : matchExactOptionsNoFacets
                      "
                    >
                    </b-select>
                  </div>
                  <div
                    class="facet-input-container"
                    v-for="(facet, facetIndex) in trigger.facets.filters"
                    :key="'facet_' + index + facetIndex"
                  >
                    <div
                      class="delete"
                      @click="deleteFacet(index, facetIndex)"
                    ></div>
                    <div class="type">
                      <b-select
                        v-model="facet.type"
                        :options="facetOptions"
                        @change="facetTypeChange(index, facetIndex)"
                      >
                      </b-select>
                    </div>
                    <div class="value" v-if="facet.type == 'price'">
                      <b-input
                        min="0"
                        type="number"
                        v-model="priceValues[index][facetIndex].from"
                        @change="
                          () => {
                            priceValues = priceValues
                          }
                        "
                        placeholder="from"
                      >
                      </b-input>
                      <b-input
                        min="0"
                        type="number"
                        v-model="priceValues[index][facetIndex].to"
                        @change="
                          () => {
                            priceValues = priceValues
                          }
                        "
                        placeholder="to"
                      >
                      </b-input>
                    </div>
                    <div class="value" v-else-if="facet.type == 'categories'">
                      <b-input
                        v-model="facet.value"
                        @focus="showAutocomplete = true"
                        @blur="onInputBlur"
                        @input="
                          () => {
                            triggers = triggers
                          }
                        "
                        autocomplete="off"
                      >
                      </b-input>
                      <div
                        class="autocomplete"
                        v-if="facet.value.length > 0 && showAutocomplete"
                      >
                        <div
                          class="list-item"
                          v-for="category in filteredCategoriesList(
                            facet.value
                          )"
                          :key="'category_' + category"
                          @click="
                            () => {
                              facet.value = category
                              triggers = triggers
                            }
                          "
                        >
                          {{ category }}
                        </div>
                      </div>
                    </div>
                    <div class="value" v-else-if="facet.type !== 'null'">
                      <b-input
                        v-model="facet.value"
                        @input="
                          () => {
                            triggers = triggers
                          }
                        "
                      >
                      </b-input>
                    </div>
                  </div>
                  <div class="btn-container">
                    <div
                      :class="[
                        'add',
                        'btn',
                        { disabled: cantAdd[index].facet },
                      ]"
                      @click="
                        addFacet(index)
                        touchedTrigger = true
                      "
                    >
                      {{ $t("visualMachine.addFacet") }}
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="add-trigger" v-if="showAddTrigger" @click="addTrigger">
          + {{ $t("visualMachine.addTrigger") }}
        </div>
        <div
          :class="[
            'collapse-container',
            { expanded: actionExpanded['action_' + index] },
            { 'with-error': cantAddActionArray[index] && touchedActions },
          ]"
          v-for="(action, index) in selectedBanner.actions"
          :key="'action_' + index"
        >
          <div
            class="header-collapse"
            @click="() => expandAccordion('action', index)"
          >
            <div
              class="warning actions"
              v-if="cantAddActionArray[index] && touchedActions"
              @mouseenter="$bvToast.show('actions-toast_' + index)"
              @mouseleave="$bvToast.hide('actions-toast_' + index)"
            >
              <div class="warn-icon"></div>
              <b-toast
                :id="'actions-toast_' + index"
                variant="danger"
                static
                no-close-button
                no-auto-hide
                solid
              >
                <span
                  v-for="(warning, warningIndex) in cantAddActionWarnings(
                    index
                  )"
                  :key="'warning_action_' + warningIndex"
                >
                  <br v-if="warningIndex > 0" />
                  {{ warning }}
                </span>
              </b-toast>
            </div>
            <div class="title-container">
              <div
                class="delete"
                v-if="index > 0"
                @click="deleteAction(index)"
              ></div>
              <div class="label-container">
                <div class="sub-title">
                  {{ $t("banners.banner", { var1: index + 1 }) }}
                </div>
                <div class="desc">{{ $t("banners.addCustomBanner") }}</div>
              </div>
              <div class="banner-id">
                <div class="label">{{ $t("banners.bannerId") }}</div>
                <b-input
                  v-model="action.name"
                  @input="(event) => handleActionChange(event, index)"
                >
                </b-input>
              </div>
            </div>

            <div class="icon"></div>
          </div>
          <b-collapse
            accordion="accordion"
            v-model="actionExpanded['action_' + index]"
          >
            <div class="accordion-content">
              <div class="container">
                <div class="action-input-container">
                  <div class="column">
                    <div class="title">{{ $t("banners.bannerType") }}</div>
                    <div class="label">{{ $t("banners.selectType") }}</div>
                    <b-select
                      v-model="action.type"
                      :options="actionTypeOptions"
                      @change="
                        () => {
                          action.position =
                            action.type == 'bannerListing' ? 0 : maxPosition + 1
                          touchedActions = true
                        }
                      "
                    >
                    </b-select>
                  </div>
                  <div class="column">
                    <div class="title">{{ $t("banners.pointingUrl") }}</div>
                    <div class="label">{{ $t("banners.addLink") }}</div>
                    <b-input v-model="action.url"> </b-input>
                  </div>
                </div>
                <div class="action-input-container">
                  <div class="column">
                    <div class="title">
                      {{ $t("banners.desktopImageLink") }}
                    </div>
                    <div class="label">{{ $t("banners.bannerEst") }}</div>
                    <b-input
                      v-model="action.imageUrl"
                      :id="'img_placeholder_' + index"
                    >
                    </b-input>
                    <b-popover
                      :target="'img_placeholder_' + index"
                      triggers="hover"
                      placement="top"
                    >
                      <img
                        :src="action.imageUrl"
                        :alt="action.imageUrl"
                        @error="replaceByDefault"
                      />
                    </b-popover>
                    <div class="sub-label" v-if="action.type == 'bannerCard'">
                      {{ $t("banners.inThisCase") }}
                    </div>
                  </div>
                  <div class="column">
                    <div class="title">{{ $t("banners.mobileImageLink") }}</div>
                    <div class="label">{{ $t("banners.bannerEst") }}</div>
                    <b-input
                      v-model="action.mobileImageUrl"
                      :id="'img_mobile_placeholder_' + index"
                    >
                    </b-input>
                    <b-popover
                      :target="'img_mobile_placeholder_' + index"
                      triggers="hover"
                      placement="top"
                    >
                      <img
                        :src="action.mobileImageUrl"
                        :alt="action.mobileImageUrl"
                        @error="replaceByDefault"
                      />
                    </b-popover>
                    <div class="sub-label" v-if="action.type == 'bannerCard'">
                      {{ $t("banners.inThisCase") }}
                    </div>
                  </div>
                </div>
                <div
                  class="action-input-container"
                  v-if="action.type == 'bannerCard'"
                >
                  <div class="column">
                    <div class="title">{{ $t("banners.cardPosition") }}</div>
                    <div class="label">{{ $t("banners.choosePosition") }}</div>
                    <div class="btn" @click="openPanel(action.type, [], index)">
                      <div class="icon"></div>
                      {{ $t("banners.choosePosition") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="add-trigger" v-if="!cantAddActions" @click="addAction()">
          + {{ $t("banners.addBanner") }}
        </div>
        <div class="cta-container">
          <div
            :class="['btn', 'delete', { disabled: !selectedBanner.id }]"
            @click="showDeleteModal = true"
          >
            {{ $t("banners.deleteBanner") }}
          </div>
          <div
            :class="['btn', 'save', { disabled: cantSaveCampaign }]"
            @click="saveCampaign"
          >
            {{
              selectedBanner.id
                ? $t("banners.updateBanner")
                : $t("banners.createBanner")
            }}
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import axios from "@/utils/Axios"
import lodash from "lodash"
import moment from "moment"
import Utils from "@/utils/Utils"
import ASPanel from "@/components/ASPanel/ASPanel"

export default {
  name: "Banners",
  DRAG_OPTIONS: Utils.DRAG_OPTIONS,
  EMPTY_BANNER: {
    name: "",
    description: "",
    isActive: true,
    validity: {
      type: "always",
      from: 0,
      to: null,
    },
    conditions: {
      type: "disjunction",
      conditions: [
        {
          type: "conjunction",
          conditions: [
            {
              type: "filterContains",
              filters: [],
            },
          ],
        },
      ],
    },
    actions: [
      {
        type: "bannerListing", //bannerCard
        url: "",
        imageUrl: "",
        mobileImageUrl: "",
        position: 0,
        name: "",
        id: null,
      },
    ],
  },
  data() {
    return {
      isloadingBannersList: true,
      bannersList: [],
      filterWord: "",
      campaignInfoExpanded: false,
      schedulingExpanded: false,
      changedWebsite: false,
      position: -1,
      triggersExpanded: {
        trigger_0: false,
      },
      actionExpanded: {
        action_0: false,
      },
      locale: {
        applyLabel: this.$t("visualMachine.applyLabel"),
        cancelLabel: this.$t("visualMachine.cancel"),
        format: "dd mmm yyyy HH:MM",
      },
      toggledAll: false,
      facetOptions: [],
      showPanel: false,
      actionType: "",
      bannersIndex: 0,
      showDeleteTriggerModal: false,
      showDeleteModal: false,
      modalTitle: "",
      modalDesc: "",
      deletingTriggerIndex: 0,
      website: "",
      categoriesList: [],
      showAutocomplete: false,
      touchedName: false,
      touchedTrigger: false,
      touchedActions: false,
      validityOptions: [
        { value: "always", text: this.$t("banners.runBannerAlways") },
        { value: "timeInterval", text: this.$t("banners.runBannerSpecific") },
      ],
      queryOptions: [
        { value: "queryContains", text: this.$t("visualMachine.matches") },
        { value: "queryIs", text: this.$t("visualMachine.matchesExactly") },
        { value: "always", text: this.$t("visualMachine.all") },
      ],
      matchExactOptions: [
        { value: "filterIs", text: this.$t("visualMachine.specificFilters") },
        { value: "filterContains", text: this.$t("visualMachine.anyFilter") },
      ],
      matchExactOptionsNoFacets: [
        { value: "filterIs", text: this.$t("visualMachine.justQuery") },
        {
          value: "filterContains",
          text: this.$t("visualMachine.queryFilters"),
        },
      ],
      actionTypeOptions: [
        { value: "bannerCard", text: this.$t("banners.card") },
        { value: "bannerListing", text: this.$t("banners.listing") },
      ],
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentConfiguration",
      "getBannerPosition",
      "getShowDiscardChangesModal",
      "getSelectedBanner",
      "getPendingRouting",
      "getDisplayedWebsites",
    ]),
    showAddTrigger() {
      var lastTrigger = this.triggers.slice(-1)[0]
      return lastTrigger
        ? lastTrigger.facets.filters.length > 0 ||
            lastTrigger.queries.length > 0
        : false
    },
    actions() {
      return this.selectedBanner ? this.selectedBanner.actions : []
    },
    showDiscardChangesModal: {
      get() {
        return this.getShowDiscardChangesModal
      },
      set(value) {
        this.setShowDiscardChangesModal(value)
      },
    },
    toggleDynamic: {
      get() {
        return !!this.getCurrentConfiguration.layout.dynamicBanner
      },
      async set(value) {
        var currentConfiguration = lodash.cloneDeep(
          this["getCurrentConfiguration"].layout
        )
        currentConfiguration.dynamicBanner = value
        this.setCurrentConfigurationByField({
          field: "layout",
          value: currentConfiguration,
        })
        var objectToSend = {}
        objectToSend.layout = lodash.cloneDeep(currentConfiguration)
        await axios
          .put(
            Utils.SHOPS + "/" + this.getCurrentConfiguration.id + Utils.CONF,
            objectToSend
          )
          .then((response) => {
            this.setWebsites(response.data)
            this.setCurrentConfiguration(
              lodash.clone(
                Utils.getConfigurationFromWebsite(
                  response.data,
                  this.getCurrentConfiguration
                )
              )
            )
          })
      },
    },
    selectedBanner: {
      get() {
        return this.getSelectedBanner
      },
      set(value) {
        this.setSelectedBanner(value)
      },
    },
    timeframes: {
      get() {
        return {
          startDate: this.selectedBanner.validity.from * 1000,
          endDate: this.selectedBanner.validity.to * 1000,
        }
      },
      set(value) {
        (this.selectedBanner.validity.from = value.startDate
          ? value.startDate.getTime()/1000
          : 0),
          (this.selectedBanner.validity.to = value.endDate
            ? value.endDate.getTime()/1000
            : 0)
      },
    },
    triggers: {
      get() {
        var objToReturn = []
        objToReturn = this.selectedBanner.conditions.conditions.map(
          (trigger) => {
            var facetsContainer = trigger.conditions.filter((item) =>
              this.matchExactOptions
                .map((facetOption) => facetOption.value)
                .includes(item.type)
            )[0]
            return {
              queries: trigger.conditions.filter((item) =>
                this.queryOptions
                  .map((queryOption) => queryOption.value)
                  .includes(item.type)
              ),
              facets: {
                type: facetsContainer.type,
                filters: facetsContainer.filters.flat().map((item) => {
                  return {
                    type: Object.keys(item)[0],
                    value: Object.values(item)[0],
                  }
                }),
              },
            }
          }
        )
        return objToReturn
      },
      set(values) {
        this.selectedBanner.conditions.conditions = values.map((value) => {
          return {
            type: "conjunction",
            conditions: [
              ...value.queries,
              {
                type: value.facets.type,
                filters: value.facets.filters.map((facet) => {
                  var objectToReturn = {}
                  objectToReturn[facet.type] = facet.value
                  return [objectToReturn]
                }),
              },
            ],
          }
        })
      },
    },
    cantAdd() {
      return this.triggers.map((trigger) => {
        return {
          query: trigger.queries.some(
            (query) =>
              query.type != this.queryOptions[0].value ||
              (query.type == this.queryOptions[0].value && query.value == "")
          ),
          facet:
            trigger.facets.filters.some(
              (facet) => facet.type == null || facet.value == ""
            ) ||
            trigger.facets.filters.length ==
              this.getCurrentConfiguration.setup.facets.length + 2,
        }
      })
    },
    priceValues: {
      get() {
        return this.triggers.map((trigger) =>
          trigger.facets.filters.map((filter) => {
            return {
              from: filter.value.from,
              to: filter.value.to,
            }
          })
        )
      },
      set(value) {
        var triggers = lodash.cloneDeep(this.triggers)
        value.forEach((item, index) => {
          item.forEach((value, filterIndex) => {
            if (value.from || value.to) {
              triggers[index].facets.filters[filterIndex].value = {}
              triggers[index].facets.filters[
                filterIndex
              ].value.from = value.from ? parseInt(value.from) : 0
              triggers[index].facets.filters[filterIndex].value.to = value.to
                ? parseInt(value.to)
                : 0
            }
          })
        })
        this.triggers = triggers
      },
    },
    bannersListFiltered() {
      return this.bannersList.filter((campaign) =>
        campaign.name.toUpperCase().includes(this.filterWord.toUpperCase())
      )
    },
    cantAddName() {
      return this.selectedBanner.name == ""
    },
    cantAddValidity() {
      return (
        this.selectedBanner.validity.type == this.validityOptions[1].value &&
        this.selectedBanner.validity.from == 0
      )
    },
    cantAddFilterArray() {
      return this.selectedBanner.conditions.conditions.map((subCondition) =>
        this.cantAddFilter(subCondition)
      )
    },
    cantAddActionArray() {
      return this.selectedBanner.actions.map((action, index) =>
        this.cantAddAction(action, index)
      )
    },
    cantAddFilters() {
      return this.cantAddFilterArray.some((item) => item)
    },
    cantAddActions() {
      return this.cantAddActionArray.some((item) => item)
    },
    cantSaveCampaign() {
      return (
        this.cantAddName ||
        this.cantAddValidity ||
        this.cantAddFilters ||
        this.cantAddActions
      )
    },
    maxPosition() {
      var positions = this.selectedBanner
        ? this.selectedBanner.actions.map((action) =>
            action.position ? action.position : 0
          )
        : [0]
      return positions.reduce((a, b) => Math.max(a, b))
    },
  },
  methods: {
    ...mapActions([
      "setBannerPosition",
      "setShowDiscardChangesModal",
      "setSelectedBanner",
      "setCurrentConfiguration",
      "setOpenedWebsitesList",
      "setPendingRouting",
      "setCurrentConfigurationByField",
      "setWebsites",
    ]),
    async handleActionChange(value, index) {
      if (value.length == 0) {
        await axios
          .get(Utils.BANNER_ID)
          .then(
            (res) =>
              (this.actions[index].name =
                this.selectedBanner.name + "_" + res?.data?.uuid)
          )
      }
    },
    selectCampaign(value) {
      this.selectedBanner = lodash.cloneDeep(value)
      this.campaignInfoExpanded = true
      this.schedulingExpanded = false
      this.touchedName = false
      this.touchedTrigger = false
      this.touchedActions = false
      value.conditions.conditions.forEach((trigger, index) => {
        this.triggersExpanded["trigger_" + index] = false
      })
      value.actions.forEach((action, index) => {
        this.actionExpanded["action_" + index] = false
      })
    },
    replaceByDefault(e) {
      e.target.src = require("@/assets/icons/img-not found.svg")
    },
    addQuery(triggerIndex) {
      var triggers = lodash.cloneDeep(this.triggers)
      var queries = lodash.cloneDeep(triggers[triggerIndex].queries)
      queries.push({ type: this.queryOptions[0].value, value: "" })
      triggers[triggerIndex].queries = queries
      this.triggers = triggers
    },
    addFacet(triggerIndex) {
      var triggers = lodash.cloneDeep(this.triggers)
      var facets = lodash.cloneDeep(triggers[triggerIndex].facets.filters)
      facets.push({ type: null, value: "" })
      triggers[triggerIndex].facets.filters = facets
      this.triggers = triggers
    },
    deleteQuery(triggerIndex, queryIndex) {
      var triggers = lodash.cloneDeep(this.triggers)
      var queries = lodash.cloneDeep(triggers[triggerIndex].queries)
      queries.splice(queryIndex, 1)
      triggers[triggerIndex].queries = queries
      this.triggers = triggers
    },
    deleteFacet(triggerIndex, facetIndex) {
      var triggers = lodash.cloneDeep(this.triggers)
      var facets = lodash.cloneDeep(triggers[triggerIndex].facets.filters)
      facets.splice(facetIndex, 1)
      triggers[triggerIndex].facets.filters = facets
      this.triggers = triggers
    },
    async addAction() {
      var actions = lodash.cloneDeep(this.selectedBanner.actions)
      this.touchedActions = true
      const newPosition = this.maxPosition + 1
      let banner_id = ""
      await axios
        .get(Utils.BANNER_ID)
        .then((res) => (banner_id = res?.data?.uuid))
      actions.push({
        type: this.actionTypeOptions[0].value,
        position: newPosition,
        imageUrl: "",
        mobileImageUrl: "",
        url: "",
        name: this.selectedBanner.name + "_" + banner_id,
      })
      this.selectedBanner.actions = actions
    },
    deleteAction(index) {
      var actions = lodash.cloneDeep(this.selectedBanner.actions)
      actions.splice(index, 1)
      this.selectedBanner.actions = actions
    },
    addTrigger() {
      var triggers = lodash.cloneDeep(this.triggers)
      triggers.push({
        queries: [],
        facets: { filters: [], type: this.matchExactOptions[0].value },
      })
      this.triggers = triggers
      this.campaignInfoExpanded = false
      this.schedulingExpanded = false
      var objectToSet = {}
      triggers.forEach((trigger, index) => {
        objectToSet["trigger_" + index] = false
      })
      objectToSet["trigger_" + (triggers.length - 1)] = true
      this.triggersExpanded = lodash.cloneDeep(objectToSet)
      objectToSet = {}
      this.selectedBanner.actions.forEach((action, index) => {
        objectToSet["action_" + index] = false
      })
      objectToSet["action_" + (this.selectedBanner.actions.length - 1)] = true
      this.actionExpanded = lodash.cloneDeep(objectToSet)
    },
    async saveCampaign() {
      if (this.selectedBanner.id) {
        await axios
          .put(
            Utils.SHOPS +
              "/" +
              this.getCurrentConfiguration.id +
              Utils.VM +
              "/" +
              this.selectedBanner.id +
              "?tier=1",
            this.selectedBanner
          )
          .then(() => {
            this.selectedBanner = null
          })
      } else
        await axios
          .post(
            Utils.SHOPS +
              "/" +
              this.getCurrentConfiguration.id +
              Utils.VM +
              "?tier=1",
            this.selectedBanner
          )
          .then(() => {
            this.selectedBanner = null
          })
      this.isloadingBannersList = true
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.VM +
            "?tier=1"
        )
        .then((response) => {
          this.bannersList = response.data.campaigns
        })
        .finally(() => (this.isloadingBannersList = false))
    },
    async copyCampaign(campaign) {
      let localBanner = lodash.cloneDeep(campaign)
      delete localBanner.id
      localBanner.name += " - copy"
      await axios.post(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.VM +
          "?tier=1",
        localBanner
      )
      this.isloadingBannersList = true
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.VM +
            "?tier=1"
        )
        .then((response) => {
          this.bannersList = response.data.campaigns
        })
        .finally(() => (this.isloadingBannersList = false))
    },
    async deleteCampaign(campaignId) {
      this.isloadingBannersList = true
      await axios.delete(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.VM +
          "/" +
          campaignId +
          "?tier=1"
      )
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.VM +
            "?tier=1"
        )
        .then((response) => {
          this.bannersList = response.data.campaigns
        })
      this.isloadingBannersList = false
    },
    async saveAll() {
      this.isloadingBannersList = true
      await axios.put(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.VM +
          "?tier=1",
        { campaigns: this.bannersList }
      )
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.VM +
            "?tier=1"
        )
        .then((response) => {
          this.bannersList = response.data.campaigns
        })
      this.isloadingBannersList = false
    },
    validityText(validity) {
      const momentFormat = "lll"
      return (
        moment(validity.from*1000).format(momentFormat) +
        " - " +
        moment(validity.to*1000).format(momentFormat)
      )
    },
    async onChange(campaign) {
      this.isloadingBannersList = true
      await axios.put(
        Utils.SHOPS +
          "/" +
          this.getCurrentConfiguration.id +
          Utils.VM +
          "/" +
          campaign.id +
          "?tier=1",
        campaign
      )
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.VM +
            "?tier=1"
        )
        .then((response) => {
          this.bannersList = response.data.campaigns
        })
      this.isloadingBannersList = false
    },
    facetTypeChange(index, facetIndex) {
      var triggers = lodash.cloneDeep(this.triggers)
      var facet = triggers[index].facets.filters[facetIndex]
      if (facet.type != "price")
        triggers[index].facets.filters[facetIndex].value = ""
      else
        triggers[index].facets.filters[facetIndex].value = {
          from: null,
          to: null,
        }
      this.triggers = triggers
    },
    openPanel(actionType, products, index) {
      this.$refs.aspanel.initializeList()
      this.bannersIndex = index
      this.actionType = actionType
      // this.setBannerPosition(position);
      this.showPanel = true
    },
    onClosePanel() {
      this.showPanel = false
      this.touchedActions = true
      this.selectedBanner.actions[
        this.bannersIndex
      ].position = this.getBannerPosition
    },
    cantAddFilter(condition) {
      var filtersArray = condition.conditions
        .slice(-1)[0]
        .filters.map((item) => item[0])
        .map((item) => Object.keys(item)[0])
      return (
        condition.conditions.some((subCondition) =>
          subCondition.filters
            ? subCondition.filters.some((filter) => {
                var key = Object.keys(filter[0])[0]
                return (
                  key == "null" ||
                  filter[0][key] == "" ||
                  (key == "price" && filter[0].price.from >= filter[0].price.to)
                )
              })
            : (subCondition.type == this.queryOptions[0].value ||
                subCondition.type == this.queryOptions[1].value) &&
              subCondition.value == ""
        ) ||
        (condition.conditions.length == 1 &&
          condition.conditions[0].filters.length == 0) ||
        this.hasDuplicates(filtersArray)
      )
    },
    cantAddFilterWarnings(index) {
      var arrayToReturn = []
      var condition = this.selectedBanner.conditions.conditions[index]
      var filtersArray = condition.conditions
        .slice(-1)[0]
        .filters.map((item) => item[0])
        .map((item) => Object.keys(item)[0])
      if (
        condition.conditions.length == 1 &&
        condition.conditions[0].filters.length == 0
      )
        arrayToReturn.push(this.$t("visualMachine.almostAQuery"))
      if (this.hasDuplicates(filtersArray))
        arrayToReturn.push(this.$t("visualMachine.twoConditions"))
      condition.conditions.forEach((subCondition) => {
        if (
          subCondition.filters &&
          subCondition.filters.some((filter) => {
            var key = Object.keys(filter[0])[0]
            return (
              key == "null" ||
              filter[0][key] == "" ||
              (key == "price" && filter[0].price.from >= filter[0].price.to)
            )
          })
        )
          arrayToReturn.push(this.$t("visualMachine.filterCondition"))
        if (
          (subCondition.type == this.queryOptions[0].value ||
            subCondition.type == this.queryOptions[1].value) &&
          subCondition.value == ""
        )
          arrayToReturn.push(this.$t("visualMachine.queryCondition"))
      })
      return arrayToReturn
    },
    cantAddAction(action, index) {
      return (
        action.imageUrl == "" ||
        action.mobileImageUrl == "" ||
        (this.selectedBanner.actions.filter(
          (someAction) => someAction.type == "bannerListing"
        ).length > 1 &&
          action.type == "bannerListing") ||
        this.selectedBanner.actions.some(
          (someAction, someIndex) =>
            someIndex != index &&
            someAction.position == action.position &&
            action.type != "bannerListing" &&
            someAction.type != "bannerListing"
        )
      )
    },
    cantAddActionWarnings(index) {
      var arrayToReturn = []
      var action = this.selectedBanner.actions[index]
      if (action.imageUrl == "" || action.mobileImageUrl == "")
        arrayToReturn.push(this.$t("banners.insertImageUrl"))
      if (
        this.selectedBanner.actions.some(
          (someAction, someIndex) =>
            someIndex != index &&
            someAction.position == action.position &&
            action.type != "bannerListing" &&
            someAction.type != "bannerListing"
        )
      )
        arrayToReturn.push(this.$t("banners.twoBanners"))
      if (
        this.selectedBanner.actions.filter(
          (someAction) => someAction.type == "bannerListing"
        ).length > 1 &&
        action.type == "bannerListing"
      )
        arrayToReturn.push(this.$t("banners.oneBanner"))
      return arrayToReturn
    },
    dynamicToggleAction(value) {
      if (value.value) this.toggleAll({ value: false })
    },
    toggleAll(value) {
      this.bannersList = this.bannersList.map((campaign) => {
        var itemToReturn = lodash.cloneDeep(campaign)
        itemToReturn.isActive = value.value
        return itemToReturn
      })
      this.saveAll()
    },
    async initializeBannersList() {
      this.selectedBanner = null
      this.isloadingBannersList = true
      axios
        .get(
          Utils.SHOPS +
            "/" +
            this.getCurrentConfiguration.id +
            Utils.VM +
            "?tier=1"
        )
        .then((response) => {
          this.bannersList = response.data.campaigns
        })
        .finally(() => (this.isloadingBannersList = false))
    },
    async exitWithoutSave() {
      if (this.changedWebsite) {
        this.showDiscardChangesModal = false
        this.setOpenedWebsitesList(false)
        await this.setCurrentConfiguration(
          lodash.clone(
            Utils.getConfigurationFromWebsite(
              this.getDisplayedWebsites,
              this.website
            )
          )
        )
        this.initializeBannersList()
        this.changedWebsite = false
      } else {
        this.$router.push({ name: this.getPendingRouting })
        this.setPendingRouting("")
        this.selectedBanner = null
        this.showDiscardChangesModal = false
      }
    },
    deleteTrigger(index) {
      this.deletingTriggerIndex = index
      this.showDeleteTriggerModal = true
    },
    hasDuplicates(array) {
      return lodash.uniq(array).length !== array.length
    },
    intersection(array) {
      var arrayToReturn = array.map((item) => item.map((subitem) => subitem.id))
      if (array.length == 1) return false
      return arrayToReturn.some((item, index) =>
        arrayToReturn.some((subitem, subindex) => {
          if (index < subindex)
            return lodash.intersection(item, subitem).length >= 1
        })
      )
    },
    filteredCategoriesList(value) {
      return this.categoriesList.filter(
        (item, index) =>
          item.toUpperCase().includes(value.toUpperCase()) && index < 10
      )
    },
    onInputBlur() {
      setTimeout(() => {
        this.showAutocomplete = false
      }, 150)
    },
    expandAccordion(type, index) {
      var valueToChange =
        type == "action"
          ? this.actionExpanded["action_" + index]
          : this.triggersExpanded["trigger_" + index]
      this.triggersExpanded = {}
      this.actionExpanded = {}
      this.selectedBanner.conditions.conditions.forEach((trigger, subIndex) => {
        this.triggersExpanded["trigger_" + subIndex] = false
      })
      this.selectedBanner.actions.forEach((action, subIndex) => {
        this.actionExpanded["action_" + subIndex] = false
      })
      if (type == "action")
        this.actionExpanded["action_" + index] = !valueToChange
      else this.triggersExpanded["trigger_" + index] = !valueToChange
    },
  },
  async mounted() {
    await this.initializeBannersList()
    this.$root.$on("before-change-website", (website) => {
      this.changedWebsite = true
      this.showDiscardChangesModal = true
      this.website = website
    })
    this.facetOptions = Object.keys(
      this.getCurrentConfiguration.setup.facets
    ).map((item) => {
      return { value: item, text: item }
    })
    this.facetOptions.unshift({
      value: null,
      text: this.$t("visualMachine.assignFacet"),
      disabled: true,
    })
    this.facetOptions.push({
      value: "price",
      text: this.$t("visualMachine.price"),
    })
    this.facetOptions.push({
      value: "categories",
      text: this.$t("visualMachine.categories"),
    })
    this.$root.$on("changed-website", () => this.initializeBannersList())
    await axios
      .get("shops/" + this.getCurrentConfiguration.key + "/search?")
      .then(
        (response) =>
          (this.categoriesList = Object.keys(response.data.stats.categories))
      )
  },
  components: { ASPanel },
}
</script>

<style src="./Banners.less" lang="less" scoped></style>
