import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home/Home";
import Login from "@/views/Home/Login/Login";
import Signup from "@/views/Home/Signup/Signup";
import Activation from "@/views/Home/Activation/Activation";
import ActivationMail from "@/views/Home/ActivationMail/ActivationMail";
import CMSLogin from "@/views/Home/CMSLogin/CMSLogin";
import ForgotPassword from "@/views/Home/ForgotPassword/ForgotPassword";
import ResetPassword from "@/views/Home/ResetPassword/ResetPassword";
import Notfound from "@/views/Notfound/Notfound";
import Terms from "@/views/Terms/Terms";
import Analytics from "@/views/Analytics/Analytics";
import AnalyticsOverview from "@/views/Analytics/AnalyticsOverview/AnalyticsOverview";
import AnalyticsFilters from "@/views/Analytics/AnalyticsFilters/AnalyticsFilters";
import AnalyticsQueries from "@/views/Analytics/AnalyticsQueries/AnalyticsQueries";
import Dashboard from "@/views/Dashboard/Dashboard";
import AccountSettings from "@/views/AccountSettings/AccountSettings";
import VisualMachine from "@/views/VisualMachine/VisualMachine";
import Campaigns from "@/views/VisualMachine/Campaigns/Campaigns";
import Banners from "@/views/VisualMachine/Banners/Banners";
import Billing from "@/views/Billing/Billing";
import LayoutDesign from "@/views/LayoutDesign/LayoutDesign";
import Support from "@/views/Support/Support";
import Wizard from "@/views/Wizard/Wizard";
import WizardStart from "@/views/Wizard/WizardStart/WizardStart";
import WizardCms from "@/views/Wizard/WizardCms/WizardCms";
import WizardShop from "@/views/Wizard/WizardShop/WizardShop";
import WizardSettings from "@/views/Wizard/WizardSettings/WizardSettings";
import Setup from "@/views/Setup/Setup";
import Preview from "@/views/Preview/Preview";
import FineTuning from "@/views/FineTuning/FineTuning";
import BillingOverview from "@/views/Billing/BillingOverview/BillingOverview";
import BillingPlans from "@/views/Billing/BillingPlans/BillingPlans";
import BillingInformation from "@/views/Billing/BillingInformation/BillingInformation";
import LayoutDesignGeneral from "@/views/LayoutDesign/LayoutDesignGeneral/LayoutDesignGeneral";
import LayoutDesignDesktop from "@/views/LayoutDesign/LayoutDesignDesktop/LayoutDesignDesktop";
import LayoutDesignMobile from "@/views/LayoutDesign/LayoutDesignMobile/LayoutDesignMobile";
import LayoutDesignCard from "@/views/LayoutDesign/LayoutDesignCard/LayoutDesignCard";
import SearchConfiguration from "@/views/Setup/SearchConfiguration/SearchConfiguration";
import AdvancedSettings from "@/views/Setup/AdvancedSettings/AdvancedSettings";
import Utils from "@/utils/Utils";
import store from "@/store";
import i18n from "@/i18n";
import axios from "@/utils/Axios";
import moment from "moment";
import lodash from "lodash";
import qs from "qs";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
      {
        path: "/",
        name: "login",
        component: Login,
      },
      {
        path: "/signup",
        name: "signup",
        component: Signup,
      },
      {
        path: "/activation",
        name: "activation",
        component: Activation,
      },
      {
        path: "/crm-login",
        name: "crm-login",
        component: CMSLogin,
      },
      {
        path: "/activation-mail",
        name: "activation-mail",
        component: ActivationMail,
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
      },
      {
        path: "/recovery",
        name: "reset-password",
        component: ResetPassword,
      },
    ],
  },
  {
    path: "/wizard",
    name: "wizard",
    component: Wizard,
    children: [
      {
        path: "",
        name: "wizard-start",
        component: WizardStart,
      },
      {
        path: "cms",
        name: "wizard-cms",
        component: WizardCms,
      },
      {
        path: "shop",
        name: "wizard-shop",
        component: WizardShop,
      },
      {
        path: "settings",
        name: "wizard-settings",
        component: WizardSettings,
      },
    ],
  },
  {
    path: "/account",
    name: "account",
    component: AccountSettings,
  },
  {
    path: "/billing",
    name: "billing",
    component: Billing,
    children: [
      {
        path: "overview",
        name: "billing-overview",
        component: BillingOverview,
      },
      {
        path: "plans",
        name: "billing-plans",
        component: BillingPlans,
      },
      {
        path: "information",
        name: "billing-information",
        component: BillingInformation,
      },
    ],
  },
  {
    path: "/support",
    name: "support",
    component: Support,
  },
  {
    path: "/fineTuning",
    name: "fineTuning",
    component: FineTuning,
  },
  {
    path: "/visualMachine",
    name: "visualMachine",
    component: VisualMachine,
    children: [
      {
        path: "campaigns",
        name: "visualMachine-campaigns",
        component: Campaigns,
      },
      {
        path: "banners",
        name: "visualMachine-banners",
        component: Banners,
      },
    ],
  },
  {
    path: "/layoutDesign",
    name: "layoutDesign",
    component: LayoutDesign,
    children: [
      {
        path: "general",
        name: "layoutDesign-general",
        component: LayoutDesignGeneral,
      },
      {
        path: "desktop",
        name: "layoutDesign-desktop",
        component: LayoutDesignDesktop,
      },
      {
        path: "mobile",
        name: "layoutDesign-mobile",
        component: LayoutDesignMobile,
      },
      {
        path: "card",
        name: "layoutDesign-card",
        component: LayoutDesignCard,
      },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/analytics",
    name: "analytics",
    component: Analytics,
    children: [
      {
        path: "overview",
        name: "analytics-overview",
        component: AnalyticsOverview,
      },
      {
        path: "filters",
        name: "analytics-filters",
        component: AnalyticsFilters,
      },
      {
        path: "queries",
        name: "analytics-queries",
        component: AnalyticsQueries,
      },
    ],
  },
  {
    path: "/setup",
    name: "setup",
    component: Setup,
    children: [
      {
        path: "search",
        name: "search-configuration",
        component: SearchConfiguration,
      },
      {
        path: "settings",
        name: "advanced-settings",
        component: AdvancedSettings,
      },
    ],
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
  },
  {
    path: "/preview",
    name: "preview",
    component: Preview,
  },
  {
    path: "/notfound",
    name: "notfound",
    component: Notfound,
  },
  { path: "*", redirect: { name: "notfound" } },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  stringifyQuery: (query) => {
    let result = qs.stringify(query, { format: "RFC1738" });
    return result ? "?" + result : "";
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log("...", to);
  var localStore = store;
  if (localStore.getters.getBaseUrl === "")
    await axios.get("config.json").then((response) => {
      axios.defaults.baseURL = response.data.baseurl + Utils.API;
      localStore.commit("setBaseUrl", response.data.baseurl);
    });
  if (localStore.getters.getLanguages.length === 0)
    await axios.get(Utils.LANGUAGES).then((response) => {
      var arrayToReturn = response.data.map((item) => ({
        value: item.iso6391,
        text: item.name,
      }));
      localStore.commit("setLanguages", arrayToReturn);
    });
  await axios
    .get(Utils.CUSTOMERS + Utils.ME)
    .then((response) => localStore.commit("setBillingInfo", response.data));
  await axios.get(Utils.USERS + Utils.ME).then((response) => {
    if (response.data.status == "ERROR") {
      if (
        localStore.getters.getLanguages
          .map((item) => item.value)
          .includes(navigator.language.substring(0, 2)) &&
        !localStore.getters.getChosenLanguage
      )
        i18n.locale = navigator.language.substring(0, 2);
    } else {
      localStore.commit("setUserInfo", response.data);
      i18n.locale = response.data.languageIso;
    }
    moment.locale(i18n.locale);
  });
  await axios
    .get(Utils.CUSTOMERS + Utils.ME + Utils.PLAN)
    .then((response) =>
      localStore.commit(
        "setSelectedPlan",
        response.data.length == 0 ? Utils.TRIAL_PLAN : response.data
      )
    );
  await axios
    .get(Utils.NOTIFICATIONS + Utils.COUNT)
    .then((response) =>
      localStore.commit("setNewNotificationCounter", response.data.unread)
    );
  if (localStore.getters.getNotificationInterval < 0) {
    var interval = setInterval(() => {
      axios.get(Utils.NOTIFICATIONS + Utils.COUNT).then((response) => {
        localStore.commit("setNewNotificationCounter", response.data.unread);
      });
    }, Utils.NOTIFICATION_INTERVAL_GAP);
    localStore.commit("setNotificationInterval", interval);
  }
  if (
    window.localStorage.getItem("token") &&
    (localStore.getters.getHistoryChanges.length == 0
      ? true
      : Utils.checkChanges(
          localStore.getters.getDisplayedWebsites,
          localStore.getters.getCurrentConfiguration
        ))
  ) {
    if (localStore.getters.getHistoryChanges.length > 0)
      localStore.commit("setHistoryChanges", []);
    await axios.get(Utils.SHOPS).then((response) => {
      localStore.commit("setWebsites", response.data);
    });
    router.app.$root.$emit("check-indexing");
    router.app.$root.$emit("check-sync");
    if (
      to.name == "wizard-settings" &&
      Utils.checkConfiguring(localStore.getters.getCurrentConfiguration)
    )
      next();
    else if (to.path == "/demo" || to.path == "/preview") next();
    else if (
      localStore.getters.getCompletedWebsites.length > 0 &&
      lodash.isEqual(
        localStore.getters.getCurrentConfiguration,
        Utils.newEmptyConfig()
      )
    ) {
      localStore.commit("setOpenedWebsitesList", false);
      localStore.commit(
        "setCurrentConfiguration",
        lodash.clone(localStore.getters.getCompletedWebsites[0])
      );
      router.push({ name: "analytics-overview" });
    } else if (
      localStore.getters.getConfiguringWebsites.length > 0 &&
      to.name !== "wizard-shop" &&
      to.name !== "wizard-settings" &&
      to.name !== "wizard-start" &&
      to.matched[0].name === "wizard"
    ) {
      await localStore.commit(
        "setCurrentConfiguration",
        lodash.clone(localStore.getters.getConfiguringWebsites[0])
      );
      await axios
        .get(
          Utils.SHOPS +
            "/" +
            localStore.getters.getConfiguringWebsites[0].id +
            Utils.ATTR
        )
        .then((response) => {
          localStore.commit("setWebsiteAttributes", response.data);
        });
      router.push({ name: "wizard-settings" });
    } else if (
      to.name == "analytics-overview" &&
      localStore.getters.getCompletedWebsites.length == 0
    )
      router.push({ name: "wizard-start" });
    else if (to.name === "billing") router.push({ name: "billing-overview" });
    else if (to.name === "layoutDesign")
      router.push({ name: "layoutDesign-general" });
    else if (to.name === "visualMachine")
      router.push({ name: "visualMachine-campaigns" });
    else if (to.name === "analytics")
      router.push({ name: "analytics-overview" });
    else if (to.name === "setup") router.push({ name: "search-configuration" });
    else if (Utils.isHomeRoute(to.name)) router.push({ name: "wizard-start" });
    else if (
      localStore.getters.getSelectedCampaign ||
      localStore.getters.getSelectedBanner
    ) {
      localStore.commit("setShowDiscardChangesModal", true);
      localStore.commit("setPendingRouting", to.name);
    } else next();
  } else if (
    localStore.getters.getHistoryChanges.length > 0 &&
    from.matched[0].name !== "wizard" &&
    to.name !== "preview"
  ) {
    localStore.commit("setShowSaveConfigModal", true);
    localStore.commit("setPendingRouting", to.name);
  } else if (
    !Utils.isHomeRoute(to.name) &&
    !window.localStorage.getItem("token")
  )
    next({ name: "login" });
  else next();
});

router.afterEach((to) => {
  if (!Utils.isHomeRoute(to.name) && !window.localStorage.getItem("token"))
    router.push({ name: "login" });
});

export default router;
